import ApplicationController from 'controllers/share/application_controller';

export default class VideoController extends ApplicationController {
  static values = { ziggeoToken: String };

  connect() {
    const player = new window.ZiggeoApi.V2.Player({
      element: this.element,
      attrs: {
        video: this.ziggeoTokenValue,
        forbidrecord: true,
      },
    });

    player.activate();
  }
}
