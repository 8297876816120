export async function initializeZiggeo(token) {
  // Ziggeo sets the window.ZiggeoApi global
  // prettier-ignore
  await Promise.all([
    import(/* webpackChunkName: 'ziggeo' */ 'ziggeo-client-sdk/build/ziggeo'),
    import(/* webpackChunkName: 'ziggeo' */ 'ziggeo-client-sdk/build/ziggeo.css'),
  ]);

  if (!window.ziggeoApp) {
    window.ziggeoApp = generateZiggeoApplication(token);
  }

  return window.ZiggeoApi.V2;
}

function generateZiggeoApplication(token) {
  return new window.ZiggeoApi.V2.Application({
    token,
    webrtc_streaming_if_necessary: true,
    webrtc_on_mobile: true,
  });
}
