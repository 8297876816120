import { Controller } from '@hotwired/stimulus';
import { elementInViewport } from 'utils';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['button', 'dropdown', 'menuItem'];
  static classes = ['rightAligned'];
  static values = {
    id: String,
    open: Boolean,
    highlightedIndex: Number,
    useStimulusClickOutside: Boolean,
  };

  connect() {
    if (this.useStimulusClickOutsideValue) {
      useClickOutside(this, { events: ['click'] });
    }

    document.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeydown.bind(this));
  }

  clickOutside() {
    this.openValue = false;
    this.onClose(true);
  }

  outsideClick = (event) => {
    if (!this.openValue) {
      return;
    }

    if (event && !this.buttonTarget.contains(event.target)) {
      this.openValue = false;
      this.onClose(true);
    }
  };

  toggle(event) {
    event.preventDefault();
    this.openValue = !this.openValue;

    if (!this.openValue) {
      this.onClose();
    } else {
      this.onOpen();
    }
  }

  setOpenClassNames() {
    this.dropdownTarget.classList.add('invisible');
    this.dropdownTarget.classList.remove('hidden');
    this.dropdownTarget.classList.add('flex');

    if (!elementInViewport(this.dropdownTarget)) {
      this.dropdownTarget.classList.remove(...this.rightAlignedClasses);
    }

    this.dropdownTarget.classList.remove('invisible');
  }

  setCloseClassNames() {
    this.dropdownTarget.classList.add('hidden');
    this.dropdownTarget.classList.remove('flex');
    this.dropdownTarget.classList.add(...this.rightAlignedClasses);
  }

  copy(event) {
    navigator.clipboard.writeText(event.currentTarget.dataset.value);
  }

  onOpen() {
    this.setOpenClassNames();
    if (!this.useStimulusClickOutsideValue) {
      window.addEventListener('click', this.outsideClick);
    }

    // set focus to the first element when dropdown is open
    this.setFocusFirstElement();
  }

  onClose(byOutsideClick = false) {
    this.setCloseClassNames();
    if (!this.useStimulusClickOutsideValue) {
      window.removeEventListener('click', this.outsideClick);
    }
    window.dispatchEvent(
      new CustomEvent('dropdown-closed', {
        detail: {
          byOutsideClick,
          id: this.idValue,
        },
      })
    );

    // clear highlightedIndex value after closing the dropdown
    this.highlightedIndexValue = 0;
  }

  setFocusFirstElement() {
    if (this.hasMenuItemTarget) {
      this.menuItemTargets[0].focus();
    }
  }

  setFocusDownElement() {
    if (this.hasMenuItemTarget) {
      this.highlightedIndexValue =
        (this.highlightedIndexValue + 1) % this.menuItemTargets.length;

      this.menuItemTargets[this.highlightedIndexValue].focus();
    }
  }

  setFocusUpElement() {
    if (this.hasMenuItemTarget) {
      this.highlightedIndexValue =
        (this.highlightedIndexValue - 1) % this.menuItemTargets.length;

      this.menuItemTargets[this.highlightedIndexValue].focus();
    }
  }

  handleKeydown(event) {
    if (event.key === 'Escape') {
      this.onClose(true);
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.setFocusDownElement();
    }

    if (event.key == 'ArrowUp') {
      event.preventDefault();
      this.setFocusUpElement();
    }
  }
}
