import { Controller } from '@hotwired/stimulus';

import { observeElementVisible } from 'utils';
import { prefersReducedMotion } from 'utils/common';
import fitText from 'utils/fittext';
import './number.scss';

export default class extends Controller {
  static targets = ['number'];
  static values = {
    number: Number,
    index: Number,
    suffix: String,
    abbreviated: Boolean,
    animated: Boolean,
    language: String,
    formattedValue: String,
  };
  static classes = ['animated', 'fadedIn'];

  get languageCode() {
    const [languageCode] = this.languageValue?.split(/-|_/);
    return languageCode;
  }

  get decimalSeparator() {
    const number = 1.1;
    // Get the decimal separator for the current language, can be comma or dot
    return number.toLocaleString(this.languageCode).substring(1, 2);
  }

  async connect() {
    this.formattedValue = this.numberValue.toLocaleString(this.languageCode);
    this.numberTarget.innerHTML = `${this.formattedValue}${this.suffixValue}`;

    if (this.animatedValue && !prefersReducedMotion()) {
      this.element.classList.add(...this.animatedClasses);
      this.observer = observeElementVisible(this.element, () => {
        this.animateNumber();
      });
    } else {
      fitText(this.numberTarget);
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  async animateNumber() {
    const numberDecimalSplit = this.formattedValue.split(this.decimalSeparator);
    const decimalPlaces = numberDecimalSplit[1]
      ? numberDecimalSplit[1].length
      : 0;

    const { CountUp } = await import(
      /* webpackChunkName: 'countup' */ 'countup.js'
    );

    fitText(this.numberTarget);
    const fontSize = this.numberTarget.querySelector('span').style.fontSize;
    this.numberTarget.style.fontSize = fontSize;

    let counter = new CountUp(this.numberTarget, this.numberValue, {
      duration: 2,
      decimalPlaces,
      decimal: this.decimalSeparator,
      useGrouping: false,
      suffix: this.suffixValue,
    });
    const fadeIndelay = this.indexValue * 150 + 50;
    this.element.style.animationDelay = `${fadeIndelay}ms`;
    this.element.classList.add(this.fadedInClass);

    setTimeout(() => {
      counter.start();
    }, fadeIndelay + 50);
  }
}
